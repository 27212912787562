import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { Entry } from "contentful";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

import BreakpointAwareAsset from "~/components/common/breakpoint-aware-asset";
import EscapeNewLine from "~/components/common/escape-new-line";
import Grid from "~/components/common/grid";
import HighlightedText from "~/components/common/highlighted-text";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";

import { CarouselWrapper } from "../carousel-utils/carousel-utils";
import EditorialLink from "../editorial-link/editorial-link";
import styles from "./brand-carousel.module.scss";

type Props = {
  entry: Entry<unknown>;
};

function BrandCarouselTab({
  entry,
  index,
  activeIndex,
}: {
  entry: Entry<Editorials.BrandCard>;
  index: number;
  activeIndex: number;
}) {
  const previousIndexRef = useRef<number | undefined>(undefined);
  const tabsRef = useRef<HTMLElement[]>([]);

  useEffect(() => {
    let scrollTimeout: NodeJS.Timeout;
    function translateAfterSelect() {
      const parentPos = tabsRef.current[activeIndex]?.parentElement?.getBoundingClientRect();
      const childPos = tabsRef.current[activeIndex]?.getBoundingClientRect();
      if (parentPos && childPos) {
        const relativeOffset = childPos.left - parentPos.left;

        tabsRef.current[activeIndex]?.parentElement?.scrollTo({ left: relativeOffset + 24, behavior: "smooth" });
      }
    }
    if ((previousIndexRef.current == undefined && activeIndex != 0) || previousIndexRef.current != undefined) {
      scrollTimeout = setTimeout(() => {
        translateAfterSelect();
      }, 500);
      previousIndexRef.current = activeIndex;
    }
    return () => clearTimeout(scrollTimeout);
  }, [activeIndex]);

  const inspectorMode = contentfulUtils.useInspectorMode(entry);

  return (
    <Tab
      id={`${entry.sys.id}-tab`}
      ref={(ref) => (tabsRef.current[index] = ref!)}
      className={({ selected }) => classNames(styles.brandTab, selected ? styles.clickedTab : undefined)}
      aria-roledescription="tab"
      aria-controls={`${entry.sys.id}-panel`}
      {...inspectorMode?.getProps("ctaTitle")}
    >
      {({ selected }) => (
        <>
          <EscapeNewLine text={entry.fields.ctaTitle} />
          {selected && (
            <motion.div className={styles.selectedBox} layoutId={`selected-BrandCarousel-${entry.sys.id}`} />
          )}
        </>
      )}
    </Tab>
  );
}

function BrandCarouselPanel({ entry, hideImage }: { entry: Entry<Editorials.BrandCard>; hideImage?: boolean }) {
  const inspectorMode = contentfulUtils.useInspectorMode(entry);

  return (
    <Tab.Panel
      as={motion.div}
      id={`${entry.sys.id}-panel`}
      aria-labelledby={`${entry.sys.id}-tab`}
      aria-roledescription="tabpanel"
      className={styles.brandPanel}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {!hideImage && (
        <div className={styles.media}>
          <BreakpointAwareAsset entry={entry.fields.media} />
        </div>
      )}
      <div className={styles.textContent}>
        <h3 {...inspectorMode?.getProps("title")} className={styles.title}>
          <span className={styles.innerTitle}>
            <HighlightedText text={entry.fields.title} />
          </span>
        </h3>
        <p {...inspectorMode?.getProps("description")} className={styles.description}>
          <EscapeNewLine text={entry.fields.description} />
        </p>
        {entry.fields.link && <EditorialLink entry={entry.fields.link!} className={styles.link} />}
      </div>
    </Tab.Panel>
  );
}

function BrandCarouselTabs({
  entry,
  hideImage,
  activeIndex,
  setActiveIndex,
  type,
}: {
  entry: Entry<Editorials.BrandCarousel>;
  hideImage?: boolean;
  activeIndex: number;
  setActiveIndex: (value: number) => void;
  type: "mobile" | "desktop";
}) {
  return (
    <Tab.Group selectedIndex={activeIndex} onChange={setActiveIndex}>
      <Tab.List
        className={classNames(styles.brandTablist, type === "mobile" ? styles.mobileOnly : undefined)}
        aria-roledescription="tablist"
      >
        {entry.fields.cards.map((card, index) => (
          <BrandCarouselTab key={card.sys.id} entry={card} index={index} activeIndex={activeIndex} />
        ))}
      </Tab.List>
      <Tab.Panels className={classNames(styles.brandTabPanels, type === "mobile" ? styles.mobileOnly : undefined)}>
        {entry.fields.cards.map((card) => (
          <BrandCarouselPanel key={card.sys.id} entry={card} hideImage={hideImage} />
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}

export default function BrandCarousel({ entry }: Props) {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  if (!isActiveEntry(entry)) return null;
  assertEditorialType<Editorials.BrandCarousel>(entry, "brandCarousel");

  entry.fields.cards = entry.fields.cards?.filter(isActiveEntry);
  if (!entry.fields.cards || entry.fields.cards.length === 0) return null;

  return (
    <AnimatePresence>
      <CarouselWrapper entry={entry}>
        <Grid className={styles.grid}>
          <BrandCarouselTabs entry={entry} activeIndex={activeIndex} setActiveIndex={setActiveIndex} type="mobile" />

          <motion.div
            key={`${entry.fields.cards[activeIndex].sys.id}-media`}
            className={classNames(styles.media, styles.desktopOnly)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <BreakpointAwareAsset entry={entry.fields.cards[activeIndex].fields.media} />
          </motion.div>
          <div className={classNames(styles.cardCol, styles.desktopOnly)}>
            <BrandCarouselTabs
              entry={entry}
              hideImage
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              type="desktop"
            />
          </div>
        </Grid>
      </CarouselWrapper>
    </AnimatePresence>
  );
}
